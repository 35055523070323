import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NO_PROXY_CONTRACT } from 'config/constants'
import { stakesConfig } from 'config/constants/stakes'
import fromPairs from 'lodash/fromPairs'
import { fetchPairAddress } from 'state/stable/fetchStakes'
import { StakesState, SerializedStake } from 'state/types'

import { fetchErc20, fetchErc20Lp, fetchPoolId, fetchPoolInfo, fetchPoolsPrices, fetchUserInfo } from './fetchStakes'

const initialState: StakesState = {
  data: [...stakesConfig],
  userDataLoaded: false,
}

export const fetchPoolsIdAsync = (chainId: number) => async (dispatch, getState) => {
  try {
    const { data } = getState('data').stable
    const poolsId = await fetchPoolId(data, chainId)
    const poolsIdMap = fromPairs(poolsId.map((entry) => [entry.sousId, entry]))
    const pool = data.map((p) => {
      const epoch = poolsIdMap[p.sousId]
      return {
        ...epoch,
      }
    })

    dispatch(setPoolsId(pool))
  } catch (e) {
    console.log('fetchPoolsIdAsync=====', e)
  }
}
const getApy = (totalStaked, point, totalPoint, pendingReward, lpPrice) => {
  if (totalStaked.isEqualTo(0)) {
    return 1000
  }
  if (point.isEqualTo(0)) {
    return 0
  }
  const pointRate = point.div(totalPoint)
  const dayRate = pendingReward.times(pointRate).div(totalStaked.times(lpPrice))
  // const apy = dayRate.plus(1).pow(365).minus(1).times(100)
  const apy = dayRate.times(365).times(100) // 复利改为单利
  if (apy.gt(99999999999)) {
    return 9999999
  }
  return apy.toFixed(2)
}

export const fetchPoolsConfig = () => async (dispatch, getState) => {
  const res = await fetch(`/pool.stable.json`)
  let data = [] as SerializedStake[]
  if (res.ok) {
    data = await res.json()
  }
  if (data.length > 0) {
    dispatch(setData(data))
  }
  // return data
}

export const fetchStakesPublicDataAsync = (chainId: number) => async (dispatch, getState) => {
  try {
    const {
      stable: { data },
    } = getState()

    const [poolsInfo, pairsAddress, lpExtInfo] = await Promise.all([
      fetchPoolInfo(data, chainId),
      fetchPairAddress(data, chainId),
      fetchErc20Lp(data, chainId),
    ])

    const pairsFilter = pairsAddress.filter((pair) => {
      return pair.earnPair !== NO_PROXY_CONTRACT
    })
    const prices = await fetchPoolsPrices(pairsFilter, chainId)

    const pricesIdMap = fromPairs(prices.map((entry) => [entry.sousId, entry]))

    const poolsInfoMap = fromPairs(poolsInfo.map((entry) => [entry.sousId, entry]))
    const pool = data.map((p) => {
      const info = poolsInfoMap[p.sousId]
      const currPool = data[p.sousId]
      const lpExt = lpExtInfo[p.sousId]

      let { totalStaked } = info
      if (currPool.isLp) {
        // LP 转换为 LP中包含的 LITE
        totalStaked = info.totalStaked.div(lpExt.data.totalSupply).times(lpExt.data.earningTokenBalance)
      }

      const earningTokenPrice = (pricesIdMap[p.sousId] as any) ? (pricesIdMap[p.sousId] as any).earningTokenPrice : 0

      const apy = getApy(totalStaked, info.allocPoint, currPool.totalPoint, currPool.pendingReward, 1)
      return {
        apy,
        earningTokenPrice,
        ...info,
      }
    })

    dispatch(setPoolsId(pool))
  } catch (e) {
    console.log('fetchStakesPublicDataAsync====', e)
  }
}

export const fetchStakesUserDataAsync = (chainId: number, account) => async (dispatch, getState) => {
  try {
    const {
      stable: { data },
    } = getState()

    const [userInfo] = await Promise.all([fetchUserInfo(data, chainId, account)])
    const userInfoMap = fromPairs(userInfo.map((entry) => [entry.sousId, entry]))
    const pool = data.map((p) => {
      const user = userInfoMap[p.sousId]
      return {
        ...user,
      }
    })
    dispatch(setPoolUserData(pool))
  } catch (e) {
    console.log('fetchStakesUserDataAsync====', e)
  }
}

export const fetchErc20DataAsync = (chainId: number, account) => async (dispatch, getState) => {
  try {
    const {
      stable: { data },
    } = getState()
    const [userInfo] = await Promise.all([fetchErc20(data, chainId, account)])
    const userInfoMap = fromPairs(userInfo.map((entry) => [entry.sousId, entry]))
    const pool = data.map((p) => {
      const user = userInfoMap[p.sousId]
      return {
        ...user,
      }
    })
    dispatch(setPoolUserData(pool))
  } catch (e) {
    console.log('fetchErc20DataAsync====', e)
  }
}

export const updateUserDataErc20 = createAsyncThunk<
  { sousId: number; value: any },
  { config: SerializedStake[]; sousId: number; account: string; chainId: number }
>('stable/updateUserDataErc20', async ({ config, chainId, sousId, account }) => {
  const userData = await fetchErc20(config, chainId, account)
  return { sousId, value: userData[sousId].data }
})

export const updateUserData = createAsyncThunk<
  { sousId: number; value: any },
  { config: SerializedStake[]; sousId: number; account: string; chainId: number }
>('stable/updateUserData', async ({ config, chainId, sousId, account }) => {
  const userData = await fetchUserInfo(config, chainId, account)
  return { sousId, value: userData[sousId].data }
})

export const StableSlice = createSlice({
  name: 'Stable',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
    setPoolsId: (state, action) => {
      state.data = state.data.map((p) => {
        const currPoolData = action.payload[p.sousId]
        return { ...p, ...currPoolData }
      })
    },
    setPoolUserData: (state, action) => {
      console.log(44, state.data)

      state.data = state.data.map((pool) => {
        const userData = { ...pool.userData, ...action.payload[pool.sousId].data }
        return { ...pool, userData }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserDataErc20.fulfilled, (state, action: PayloadAction<{ sousId: number; value: any }>) => {
      const { value, sousId } = action.payload
      const index = state.data.findIndex((p) => p.sousId === sousId)
      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, ...value } }
      }
    })
    builder.addCase(updateUserData.fulfilled, (state, action: PayloadAction<{ sousId: number; value: any }>) => {
      const { value, sousId } = action.payload
      const index = state.data.findIndex((p) => p.sousId === sousId)
      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, ...value } }
      }
    })
    // builder.addMatcher(isAnyOf(
    //   updateUserAllowance.fulfilled,
    //   updateUseStakingTokenBalance.fulfilled
    // ),(state, action: PayloadAction<{ sousId: number; field: string; value: any }>) => {
    //   const { field, value, sousId } = action.payload
    //   const index = state.data.findIndex((p) => p.sousId === sousId)
    //   if (index >= 0) {
    //     state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
    //   }
    // })
  },
})

// Actions
export const { setPoolsId, setPoolUserData, setData } = StableSlice.actions

export default StableSlice.reducer
