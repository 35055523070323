import multicall from 'utils/multicall'
import mintABI from 'config/abi/mint.json'
import { getMintAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { formatEther, formatUnits, parseUnits } from '@ethersproject/units'
import { MINT_LUSD_CURRENCY } from 'config/constants/exchange'
import { formatBigNumberToFixed } from 'utils/formatBalance'
import { Field } from 'state/stableMint/actions'
import { Currency } from '../../../packages/swap-sdk/src/entities'

export const fetchLimit = async (chainId) => {
  const calls = [
    {
      address: getMintAddress(chainId),
      name: 'getMintLimit',
    },
    {
      address: getMintAddress(chainId),
      name: 'getBurnLimit',
    },
  ]

  // const chunkSize = calls.length / 2
  const result = await multicall(mintABI as any[], calls, chainId)
  // const poolsIdsChunk: any[] = chunk(poolsIdsCallsResult, chunkSize)

  // console.log(222, 'fetchLimit===', result)

  return {
    mintDayLimit: formatEther(new BigNumber(result[0].dayLimit.toString()).toJSON()),
    mintHourLimit: formatEther(new BigNumber(result[0].hourLimit.toString()).toJSON()),
    mintMaxLimit: formatEther(new BigNumber(result[0].maxAmount.toString()).toJSON()),
    burnDayLimit: formatEther(new BigNumber(result[1].dayLimit.toString()).toJSON()),
    burnHourLimit: formatEther(new BigNumber(result[1].hourLimit.toString()).toJSON()),
    burnMaxLimit: formatEther(new BigNumber(result[1].maxAmount.toString()).toJSON()),
  }
}

export const fetchAmount = async (chainId: number, currencies: { [field in Field]?: Currency }, inAmount: string) => {
  const isMint = MINT_LUSD_CURRENCY.toLowerCase() === currencies[Field.OUTPUT].wrapped.address.toLowerCase()
  const token = isMint ? currencies[Field.INPUT] : currencies[Field.OUTPUT]

  const calls = [
    {
      address: getMintAddress(chainId),
      name: isMint ? 'getSwapUsd' : 'getSwapToken',
      params: [parseUnits(inAmount, token.decimals), token.wrapped.address],
    },
  ]

  // const chunkSize = calls.length / 2
  const result = await multicall(mintABI as any[], calls, chainId)
  // const poolsIdsChunk: any[] = chunk(poolsIdsCallsResult, chunkSize)

  return {
    amount: new BigNumber(result[0].toString()),
  }
}
