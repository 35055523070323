import { createAction } from '@reduxjs/toolkit'
import { DerivedPairDataNormalized, PairDataNormalized, PairDataTimeWindowEnum } from './types'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('stableMint/selectCurrency')
export const switchCurrencies = createAction<void>('stableMint/switchCurrencies')

export const typeInput = createAction<{ field: Field; typedValue: string }>('stableMint/typeInput')
export const replaceSwapState =
  createAction<{
    field: Field
    typedValue: string
    inputCurrencyId?: string
    outputCurrencyId?: string
    recipient: string | null
  }>('stableMint/replaceSwapState')
export const setRecipient = createAction<{ recipient: string | null }>('stableMint/setRecipient')
export const updatePairData =
  createAction<{
    pairData: PairDataNormalized
    pairId: string
    timeWindow: PairDataTimeWindowEnum
  }>('stableMint/updatePairData')
export const updateDerivedPairData = createAction<{
  pairData: DerivedPairDataNormalized
  pairId: string
  timeWindow: PairDataTimeWindowEnum
}>('stableMint/updateDerivedPairData')
