import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { LusdState } from 'state/types'
import { BIG_ZERO } from 'utils/bigNumber'
import addresses from 'config/constants/contracts'
import { multicallv2 } from 'utils/multicall'
import { CAKE, USDT, LUSD } from '@pancakeswap/tokens'
import erc20Abi from 'config/abi/erc20.json'
import { fetchPublicData, fetchSwapPrice } from './fetch'

const initialState: LusdState = {
  mintAmount: '0',
  burnAmount: '0',
  publicData: {
    mintFee: 0,
    burnFee: 0,
    usdtMintWeight: 0,
    usdtBurnWeight: 0,
    hourLimitTime: 0,
    dayLimitTime: 0,
    hourMintLimit: '0',
    hourBurnLimit: '0',
    dayMintLimit: '0',
    dayBurnLimit: '0',
    hourMintLimitAmount: '0',
    dayMintLimitAmount: '0',
    hourBurnLimitAmount: '0',
    dayBurnLimitAmount: '0',
  },
}

export const fetchPublicDataAsync = createAsyncThunk<any, number>('lusd/fetchPublicData', async (chainId) => {
  const [publicData] = await Promise.all([fetchPublicData(chainId)])
  return { ...publicData }
})

export const fetchMintAmountAsync = (chainId: number, amount: string, token: string) => async (dispatch) => {
  try {
    const amt = await fetchSwapPrice(chainId, amount, token)
    dispatch(setMintAmount(amt))
  } catch (e) {
    dispatch(setMintAmount(BIG_ZERO))
  }
}

export const fetchBurnAmountAsync = (chainId: number, amount: string, token: string) => async (dispatch) => {
  try {
    const amt = await fetchSwapPrice(chainId, amount, token)
    dispatch(setBurnAmount(amt))
  } catch (e) {
    dispatch(setBurnAmount(BIG_ZERO))
  }
}

export const fetchUserDataAsync = (chainId: number, account: string) => async (dispatch) => {
  const calls = [
    {
      address: USDT[chainId].address,
      name: 'allowance',
      params: [account, addresses.lusd[chainId]],
    },
    {
      address: USDT[chainId].address,
      name: 'balanceOf',
      params: [account],
    },
    {
      address: CAKE[chainId].address,
      name: 'allowance',
      params: [account, addresses.lusd[chainId]],
    },
    {
      address: CAKE[chainId].address,
      name: 'balanceOf',
      params: [account],
    },
    {
      address: LUSD[chainId].address,
      name: 'allowance',
      params: [account, addresses.lusd[chainId]],
    },
    {
      address: LUSD[chainId].address,
      name: 'balanceOf',
      params: [account],
    },
  ]

  const [[usdtAllowance], [usdtBalance], [liteAllowance], [liteBalance], [lusdAllowance], [lusdBalance]] =
    await multicallv2({
      abi: erc20Abi,
      calls,
      chainId,
    })

  dispatch(
    setUserData({
      usdtAllowance: new BigNumber(usdtAllowance.toString()).toJSON(),
      usdtBalance: new BigNumber(usdtBalance.toString()).toJSON(),
      liteAllowance: new BigNumber(liteAllowance.toString()).toJSON(),
      liteBalance: new BigNumber(liteBalance.toString()).toJSON(),
      lusdAllowance: new BigNumber(lusdAllowance.toString()).toJSON(),
      lusdBalance: new BigNumber(lusdBalance.toString()).toJSON(),
    }),
  )
}

export const LusdSlice = createSlice({
  name: 'Lusd',
  initialState,
  reducers: {
    setPublicData: (state, action) => {
      const publicData = action.payload
      state.publicData = { ...state.publicData, ...publicData }
    },
    setUserData: (state, action) => {
      const userData = action.payload
      state.userData = { ...state.userData, ...userData }
    },
    setMintAmount: (state, action) => {
      const { payload } = action
      state.mintAmount = payload
    },
    setBurnAmount: (state, action) => {
      const { payload } = action
      state.burnAmount = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPublicDataAsync.fulfilled, (state, action) => {
      state.publicData = { ...action.payload }
    })
  },
})

// Actions
export const { setPublicData, setUserData, setMintAmount, setBurnAmount } = LusdSlice.actions

export default LusdSlice.reducer
