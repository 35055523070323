import BigNumber from 'bignumber.js'
import multicall from 'utils/multicall'
import lusdAbi from 'config/abi/lusd.json'
import addresses from 'config/constants/contracts'

export const fetchPublicData = async (chainId: number) => {
  try {
    const calls = [
      'mintFee',
      'burnFee',
      'usdtMintWeight',
      'usdtBurnWeight',
      'hourMintLimit',
      'hourBurnLimit',
      'dayMintLimit',
      'dayBurnLimit',
      'hourMintLimitAmount',
      'dayMintLimitAmount',
      'hourBurnLimitAmount',
      'dayBurnLimitAmount',
      'hourLimitTime',
      'dayLimitTime',
    ].map((method) => ({
      address: addresses.lusd[chainId],
      name: method,
    }))

    const [
      [mintFee],
      [burnFee],
      [usdtMintWeight],
      [usdtBurnWeight],
      [hourMintLimit],
      [hourBurnLimit],
      [dayMintLimit],
      [dayBurnLimit],
      [hourMintLimitAmount],
      [dayMintLimitAmount],
      [hourBurnLimitAmount],
      [dayBurnLimitAmount],
      [hourLimitTime],
      [dayLimitTime],
    ] = await multicall(lusdAbi, calls, chainId)

    return {
      mintFee: mintFee.toNumber(),
      burnFee: burnFee.toNumber(),
      usdtMintWeight: usdtMintWeight.toNumber(),
      usdtBurnWeight: usdtBurnWeight.toNumber(),
      hourLimitTime: hourLimitTime.toNumber(),
      dayLimitTime: dayLimitTime.toNumber(),
      hourMintLimit: new BigNumber(hourMintLimit.toString()).toJSON(),
      hourBurnLimit: new BigNumber(hourBurnLimit.toString()).toJSON(),
      dayMintLimit: new BigNumber(dayMintLimit.toString()).toJSON(),
      dayBurnLimit: new BigNumber(dayBurnLimit.toString()).toJSON(),
      hourMintLimitAmount: new BigNumber(hourMintLimitAmount.toString()).toJSON(),
      dayMintLimitAmount: new BigNumber(dayMintLimitAmount.toString()).toJSON(),
      hourBurnLimitAmount: new BigNumber(hourBurnLimitAmount.toString()).toJSON(),
      dayBurnLimitAmount: new BigNumber(dayBurnLimitAmount.toString()).toJSON(),
    }
  } catch (error) {
    console.error('LUSD fetchPublicData', error)
    return {
      mintFee: 0,
      burnFee: 0,
      usdtMintWeight: 0,
      usdtBurnWeight: 0,
      hourLimitTime: 0,
      dayLimitTime: 0,
      hourMintLimit: '0',
      hourBurnLimit: '0',
      dayMintLimit: '0',
      dayBurnLimit: '0',
      hourMintLimitAmount: '0',
      dayMintLimitAmount: '0',
      hourBurnLimitAmount: '0',
      dayBurnLimitAmount: '0',
    }
  }
}

export const fetchSwapPrice = async (chainId: number, amount: string, tokenAddress: string) => {
  if (amount === '0') {
    return '0'
  }

  try {
    const calls = ['getSwapToken'].map((method) => ({
      address: addresses.lusd[chainId],
      name: method,
      params: [amount, tokenAddress],
    }))

    const [[getSwapToken]] = await multicall(lusdAbi, calls, chainId)

    return new BigNumber(getSwapToken.toString()).toJSON()
  } catch (error) {
    console.error('LUSD fetchSwapPrice', error)
    return '0'
  }
}
