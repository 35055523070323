import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { AirdropState } from 'state/types'
import { BIG_ZERO } from 'utils/bigNumber'
import addresses from 'config/constants/contracts'
import { multicallv2 } from 'utils/multicall'
import { CAKE, USDT, LUSD } from '@pancakeswap/tokens'
import erc20Abi from 'config/abi/erc20.json'
import { AddressZero } from '@ethersproject/constants'
import {
  fetchPublicData,
  fetchUserData,
  fetchSplitPublicData,
  fetchSplitUserData,
  fetchSplit2PublicData,
  fetchSplit2UserData,
} from './fetch'

const initialState: AirdropState = {
  publicData: {
    maxPendingDay: 0,
    totalStaked: '0',
  },
  splitPublicData: {
    tokenAddress: AddressZero,
    totalShares: '0',
    ticketPrice: '0',
    totalReleased: '0',
    endAt: 0,
  },
  split2PublicData: {
    tokenAddress: AddressZero,
    tokenShares: '0',
    totalShares: '0',
    ticketPrice: '0',
    totalReleased: '0',
    endAt: 0,
  },
}

export const fetchPublicDataAsync = createAsyncThunk<any, number>('airdrop/fetchPublicData', async (chainId) => {
  const [publicData] = await Promise.all([fetchPublicData(chainId)])
  return { ...publicData }
})

export const fetchUserDataAsync = createAsyncThunk<any, { chainId: number; account: string }>(
  'airdrop/fetchUserData',
  async ({ chainId, account }) => {
    const [publicData] = await Promise.all([fetchUserData(chainId, account)])
    return { ...publicData }
  },
)

export const fetchSplitPublicDataAsync = createAsyncThunk<any, number>(
  'airdrop/fetchSplitPublicData',
  async (chainId) => {
    const [publicData] = await Promise.all([fetchSplitPublicData(chainId)])
    return { ...publicData }
  },
)

export const fetchSplitUserDataAsync = createAsyncThunk<any, { chainId: number; account: string }>(
  'airdrop/fetchSplitUserData',
  async ({ chainId, account }) => {
    const [publicData] = await Promise.all([fetchSplitUserData(chainId, account)])
    return { ...publicData }
  },
)

export const fetchSplit2PublicDataAsync = createAsyncThunk<any, number>(
  'airdrop/fetchSplit2PublicData',
  async (chainId) => {
    const [publicData] = await Promise.all([fetchSplit2PublicData(chainId)])
    return { ...publicData }
  },
)

export const fetchSplit2UserDataAsync = createAsyncThunk<any, { chainId: number; account: string }>(
  'airdrop/fetchSplit2UserData',
  async ({ chainId, account }) => {
    const [publicData] = await Promise.all([fetchSplit2UserData(chainId, account)])
    return { ...publicData }
  },
)

export const AirdropSlice = createSlice({
  name: 'Airdrop',
  initialState,
  reducers: {
    setPublicData: (state, action) => {
      const publicData = action.payload
      state.publicData = { ...state.publicData, ...publicData }
    },
    setUserData: (state, action) => {
      const userData = action.payload
      state.userData = { ...state.userData, ...userData }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPublicDataAsync.fulfilled, (state, action) => {
      state.publicData = { ...action.payload }
    })
    builder.addCase(fetchUserDataAsync.fulfilled, (state, action) => {
      state.userData = { ...action.payload }
    })
    builder.addCase(fetchSplitPublicDataAsync.fulfilled, (state, action) => {
      state.splitPublicData = { ...action.payload }
    })
    builder.addCase(fetchSplitUserDataAsync.fulfilled, (state, action) => {
      state.splitUserData = { ...action.payload }
    })
    builder.addCase(fetchSplit2PublicDataAsync.fulfilled, (state, action) => {
      state.split2PublicData = { ...action.payload }
    })
    builder.addCase(fetchSplit2UserDataAsync.fulfilled, (state, action) => {
      state.split2UserData = { ...action.payload }
    })
  },
})

// Actions
export const { setPublicData, setUserData } = AirdropSlice.actions

export default AirdropSlice.reducer
