import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { LaunchpadState } from 'state/types'
import { BIG_ZERO } from 'utils/bigNumber'
import addresses from 'config/constants/contracts'
import { multicallv2 } from 'utils/multicall'
import { CAKE, USDT, LUSD } from '@pancakeswap/tokens'
import erc20Abi from 'config/abi/erc20.json'
import {
  fetchArbLitePublicData,
  fetchArbLiteUserData,
  fetchArbLiteIcoPublicData,
  fetchArbLiteIcoUserData,
} from './fetch'

const initialState: LaunchpadState = {
  arbLite: {
    publicData: {
      tokenPrice: '0',
      tokenSupply: '0',
      userMaxQuota: '0',
      usdtTotal: '0',
      startTime: 0,
      endedAt: 0,
      getTokenAt: 0,
      isEmergencyRefund: false,
    },
  },
  arbLiteIco: {
    publicData: {
      tokenPrice: '0',
      tokenSupply: '0',
      usdtTotal: '0',
      endedAt: 0,
      getTokenAt: 0,
      unlockTime: 0,
    },
  },
}

export const fetchArbLitePublicDataAsync = createAsyncThunk<any, number>(
  'launchpad/fetchArbLitePublicData',
  async (chainId) => {
    const [publicData] = await Promise.all([fetchArbLitePublicData(chainId)])
    return { ...publicData }
  },
)

export const fetchArbLiteUserDataAsync = createAsyncThunk<any, { chainId: number; account: string }>(
  'launchpad/fetchArbLiteUserData',
  async ({ chainId, account }) => {
    const [publicData] = await Promise.all([fetchArbLiteUserData(chainId, account)])
    return { ...publicData }
  },
)

export const fetchArbLiteIcoPublicDataAsync = createAsyncThunk<any, number>(
  'launchpad/fetchArbLiteIcoPublicData',
  async (chainId) => {
    const [publicData] = await Promise.all([fetchArbLiteIcoPublicData(chainId)])
    return { ...publicData }
  },
)

export const fetchArbLiteIcoUserDataAsync = createAsyncThunk<any, { chainId: number; account: string }>(
  'launchpad/fetchArbLiteIcoUserData',
  async ({ chainId, account }) => {
    const [publicData] = await Promise.all([fetchArbLiteIcoUserData(chainId, account)])
    return { ...publicData }
  },
)

export const fetchArbLiteErc20DataAsync = (chainId: number, account: string) => async (dispatch) => {
  const calls = [
    {
      address: USDT[chainId].address,
      name: 'allowance',
      params: [account, addresses.launchpadArbLite[chainId]],
    },
  ]

  const [[allowance]] = await multicallv2({
    abi: erc20Abi,
    calls,
    chainId,
  })

  dispatch(
    setArbLiteUserData({
      allowance: new BigNumber(allowance.toString()).toJSON(),
    }),
  )
}

export const Launchpadlice = createSlice({
  name: 'Launchpad',
  initialState,
  reducers: {
    setArbLitePublicData: (state, action) => {
      const publicData = action.payload
      state.arbLite.publicData = { ...state.arbLite.publicData, ...publicData }
    },
    setArbLiteUserData: (state, action) => {
      const userData = action.payload
      state.arbLite.userData = { ...state.arbLite.userData, ...userData }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArbLitePublicDataAsync.fulfilled, (state, action) => {
      state.arbLite.publicData = { ...action.payload }
    })
    builder.addCase(fetchArbLiteUserDataAsync.fulfilled, (state, action) => {
      state.arbLite.userData = { ...state.arbLite.userData, ...action.payload }
    })
    builder.addCase(fetchArbLiteIcoPublicDataAsync.fulfilled, (state, action) => {
      state.arbLiteIco.publicData = { ...action.payload }
    })
    builder.addCase(fetchArbLiteIcoUserDataAsync.fulfilled, (state, action) => {
      state.arbLiteIco.userData = { ...state.arbLiteIco.userData, ...action.payload }
    })
  },
})

// Actions
export const { setArbLitePublicData, setArbLiteUserData } = Launchpadlice.actions

export default Launchpadlice.reducer
