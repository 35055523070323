import { FooterLinkType,TwitterIcon, TelegramIcon, GithubIcon } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
// import { TwitterIcon, TelegramIcon, GithubIcon } from "../Svg";


// export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
//   {
//     label: t('Developers'),
//     items: [
//       // {
//       //   label: 'Github',
//       //   href: 'https://github.com/pancakeswap',
//       //   icon: GithubIcon
//       // },
//       {
//         label: t('Audit report'),
//         href: 'https://www.certik.com/projects/liteusd',
//       },
//     ],
//   },
//   {
//     label: t('Community'),
//     items: [
//       {
//         label: 'Twitter',
//         icon: TwitterIcon,
//         href: 'https://twitter.com/LiteUsd',
//       },
//       {
//         label: 'Telegram',
//         icon: TelegramIcon,
//         href: 'https://t.me/liteusd',
//       },
//     ],
//   },
// ]

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: 'Twitter',
    icon: TwitterIcon,
    href: 'https://twitter.com/LiteUsd',
  },
  {
    label: 'Telegram',
    icon: TelegramIcon,
    href: 'https://t.me/liteusd',
  },
]
