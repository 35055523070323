import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE_MAINNET = new Token(
  ChainId.BSC,
  '0x63c9E5EAe7FF268A630D3041d5102A284D144DAc',
  18,
  'LITE',
  'LITE',
  'https://liteswap.finance/',
)

export const CAKE_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x7f728bE3E5c68e4F7f07aF3A8cdE837bE3C5f8c7',
  18,
  'LITE',
  'LITE',
  'https://liteswap.finance/',
)

export const CAKE_ARBITRUM_TEST = new Token(
  ChainId.ARBITRUM_TEST,
  '0x81758e7Fa9c78c8e1c7Ed83072A797c863F67D01',
  18,
  'LITE',
  'LITE',
  'https://liteswap.finance/',
)

export const CAKE_ARBITRUM = new Token(
  ChainId.ARBITRUM,
  '0x691168C8dF23faeaB4dF89d823F3EA56BA5c3eBc',
  18,
  'LITE',
  'LITE',
  'https://liteswap.finance/',
)

export const BCC_MAINNET = new Token(
  ChainId.BSC,
  '0x022fE283e3428c3775C4f797CdA817C133A4f8F3',
  18,
  'BCC',
  'Bichon coin',
  'https://liteswap.finance/',
)

export const BCC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x848Db7aDd3046f90aFb6ae8b4C4D79bF1767E66f',
  18,
  'BCC',
  'Bichon coin',
  'https://liteswap.finance/',
)

export const LTT_MAINNET = new Token(
  ChainId.BSC,
  '0x1aA551C6bC3878DFEE81c33f679ab315EE3badbc',
  18,
  'LTT',
  'Lottery',
  'https://liteswap.finance/',
)

export const LTT_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x49049EC9a46fd37aEDfDcedCd27E1c269a803619',
  18,
  'LTT',
  'Lottery',
  'https://liteswap.finance/',
)

export const NLITE_MAINNET = new Token(
  ChainId.BSC,
  '0xEad44e4f49b697ab98b15a4859c74E2846A9bFFD',
  18,
  'NLITE',
  'NLITE',
  'https://liteswap.finance/',
)

export const NLITE_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x9Ed534e2D53311787f585E9e83858dE68C9b07cC',
  18,
  'NLITE',
  'NLITE',
  'https://liteswap.finance/',
)

export const USDC_BSC = new Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new Token(ChainId.ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD Coin')

export const USDC_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDC_GOERLI = new Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDC_ARBITRUM = new Token(
  ChainId.ARBITRUM,
  '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  6,
  'USDC',
  'USD Coin',
)

export const USDT_BSC = new Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x13432400C1a0986547226f4dec4df582a0ce937a',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)
export const USDT_ARBITRUM = new Token(
  ChainId.ARBITRUM,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)
export const USDT_ARBITRUM_TEST = new Token(
  ChainId.ARBITRUM_TEST,
  '0x083d225BC3E2c79f1F908FD855cE85A68D0D5e26',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const LUSD_MAINNET = new Token(
  ChainId.BSC,
  '0x5b439280a2420b3eAb7A6367e36921CF1e76Aa7B',
  18,
  'LUSD',
  'LUSD',
  'https://liteswap.finance/',
)

export const LUSD_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x7897a74325Aa59414E6ac01cD847D09aE29314b3',
  18,
  'LUSD',
  'LUSD',
  'https://liteswap.finance/',
)

export const BUSD_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x8516Fc284AEEaa0374E66037BD2309349FF728eA',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4e2442A6f7AeCE64Ca33d31756B5390860BF973E',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)
export const BUSD_ARBITRUM = new Token(
  ChainId.ARBITRUM,
  '0x52095F518048fAFCfba0Ec9F93962df449Bc604e',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

// TODO: 这个BUSD的地址是什么
export const BUSD_ARBITRUM_TEST = new Token(
  ChainId.ARBITRUM_TEST,
  '0x52095F518048fAFCfba0Ec9F93962df449Bc604e',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, Token> = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.RINKEBY]: BUSD_RINKEBY,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
  [ChainId.ARBITRUM]: BUSD_ARBITRUM,
  [ChainId.ARBITRUM_TEST]: BUSD_ARBITRUM_TEST,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
  [ChainId.ARBITRUM]: CAKE_ARBITRUM,
  [ChainId.ARBITRUM_TEST]: CAKE_ARBITRUM_TEST,
}

export const LUSD = {
  [ChainId.BSC]: LUSD_MAINNET,
  [ChainId.BSC_TESTNET]: LUSD_TESTNET,
}

export const BCC = {
  [ChainId.BSC]: BCC_MAINNET,
  [ChainId.BSC_TESTNET]: BCC_TESTNET,
}

export const LTT = {
  [ChainId.BSC]: LTT_MAINNET,
  [ChainId.BSC_TESTNET]: LTT_TESTNET,
}

export const NLITE = {
  [ChainId.BSC]: NLITE_MAINNET,
  [ChainId.BSC_TESTNET]: NLITE_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.RINKEBY]: USDC_RINKEBY,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.ARBITRUM]: USDC_ARBITRUM,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.BSC_TESTNET]: USDT_TESTNET,
  [ChainId.ETHEREUM]: USDT_ETH,
  [ChainId.ARBITRUM]: USDT_ARBITRUM,
  [ChainId.ARBITRUM_TEST]: USDT_ARBITRUM_TEST,
}
