import { ChainId, Token, WBNB } from '@pancakeswap/sdk'
import { BUSD_TESTNET, CAKE_TESTNET, LUSD_TESTNET } from './common'

export const bscTestnetTokens = {
  wbnb: WBNB[ChainId.BSC_TESTNET],
  cake: CAKE_TESTNET,
  busd: BUSD_TESTNET,
  lusd: LUSD_TESTNET,
  usdt: new Token(
    ChainId.BSC_TESTNET,
    '0x47A01F129b9c95E63a50a6aa6cBaFDD96bEb4C6F',
    18,
    'USDT',
    'Tether USD',
    'https://pancakeswap.finance/',
  ),
  'lite/usdt': new Token(
    ChainId.BSC_TESTNET,
    '0xCE780c12430Cf888499Bd280AE3FAA0D2BA90190',
    18,
    'LITE-USDT',
    'LITE-USDT LP',
    'https://pancakeswap.finance/',
  ),
  syrup: new Token(
    ChainId.BSC_TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    ChainId.BSC_TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
  hbtc: new Token(ChainId.BSC_TESTNET, '0x3Fb6a6C06c7486BD194BB99a078B89B9ECaF4c82', 18, 'HBTC', 'Huobi BTC'),
  wbtc: new Token(ChainId.BSC_TESTNET, '0xfC8bFbe9644e1BC836b8821660593e7de711e564', 18, 'WBTC', 'Wrapped BTC'),
}
