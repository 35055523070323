import { multicallAddresses } from '@pancakeswap/multicall'

export default {
  masterChef: {
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
    5: '0x8CB958bBdb45597cc918147469eb650A9397aBDA',
  },
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x91C5661bF1BC4812516679253a5d2E8B856F2f5b',
    56: '0xc2e89dBb910cfb4856220D7912A5AC9B65b0ffDf',
  },
  lotteryBcc: {
    97: '0x51b5131B4d2fA98F26Cc53714a90981203030415',
    56: '0xb8938A99fadfB937Fc8A27E64c7deBeCc25568bF',
  },
  lotteryLtt: {
    97: '0x62e8fd5EbbeD2BBe12392E1210B8F2E9aFb7c0ec',
    56: '0x1A5DF7987248d6fc3928eFb708E7F7674bbec24F',
  },
  multiCall: multicallAddresses,
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeBunnies: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionFanToken: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '',
  },
  tradingCompetitionMobox: {
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '',
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    97: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
  },
  cakeFlexibleSideVault: {
    56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    97: '',
  },
  predictionsBNB: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  predictionsCAKE: {
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    97: '',
  },
  chainlinkOracleBNB: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  chainlinkOracleCAKE: {
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    97: '',
  },
  predictionsV1: {
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  potteryDraw: {
    56: '0x01871991587d5671f3A2d4E2BcDC22F4E026396e',
    97: '0xDB9D365b50E62fce747A90515D2bd1254A16EbB9',
  },
  // TODO: multi
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  iCake: {
    56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    97: '',
  },
  bCakeFarmBooster: {
    56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    97: '',
  },
  bCakeFarmBoosterProxyFactory: {
    56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    97: '',
  },
  mint: {
    56: '0xe81b9dc78909a989093B31A9CC816f1dA1297fd1',
    97: '0x8E6158c4708F0522D3233C324632b247F4972e40',
  },
  noFeeRouter: {
    56: '',
    // 56: '0x5B45448A961751Fe9aE6ABd47B0800BE658F204b',
    // 97: '0x65eCfA9781B84B5872Af84F28185aD0747CD6A06',
    97: '',
  },
  mergeLite: {
    97: '0x3E997CEbc1bE41Df3687BE4324eD6Ce78672EA18',
    56: '0x697E892B946Df759c559e278f2b30A9Bf3a7e851',
  },
  xLiteSwap: {
    97: '0x681A5F5059E288Ed8866523705ae4D0e2d9E740c',
    56: '0x725Ee48Acf084Ff99B82B389a4339D23b07961Ec',
  },
  xLiteStake: {
    97: '0xDa0A74081998a6526B084f7Fc015ea6bd87eb0b0',
    56: '0xACD6Efc840fE0d6254E3De0b66A6A7090e040d89',
  },
  lusd: {
    97: '0x7aE45C2aa6e7b459bD176d1c19F04B5d9c689535',
    56: '0x99DEF0de6fdD19450d59cDfFe59cf243f5CEfb57',
  },
  pepeDogeIdo: {
    97: '0x1F2A8440CFfcD51562fcB04C549ad7A6E619fE73',
    56: '0xB35db53e8339ef6771811eB76239dDca1Ac0b330',
  },
  xLiteLaunchpad: {
    97: '0x2242dC195441Cd73d281AABC6071e996386c6F2f',
    56: '0xB6903649B4a981F42C393ACE9D33A8614FE8fD2f',
  },
  xLiteLaunchpadIco: {
    97: '0x73bE7deB96D6351ceDE127837032AcC4D8ee9511',
    56: '',
  },
  launchpadArbLite: {
    42161: '0xa06913c3E17df7F8342bbff5fa2e070C4F731CF8',
    421613: '0x6d14923a10bE0e0b3DDd9325dB0f6f5Bfc2370E0',
  },
  launchpadArbLiteIco: {
    42161: '0x82F01C43970DfB7Aa4E016eB30D56C0045def39e',
    421613: '0xeb7637c6c355aAbdd77B654B49f425eb7Fc4C71e',
  },
  airdrop: {
    42161: '0x251a13b1d1551dB40D21EECa90d37ED1cDf3293D',
    421613: '0xfEEf63b5eeA1B7A647aDB2cF9082758F10EBcbc1',
  },
  airdropSplit: {
    42161: '0x9da2f7301796e4afFAb92Fdf739675Eef5144134',
    421613: '0xA994265b6eBc4A507b5f7e9356048693e6bBF82F',
  },
  airdropSplit2: {
    42161: '0x4fd8155b9C9B4d54463582B24112EF90Ff2bFf41',
    421613: '0x92C6f7210A1dB1d6aA7d44dc499A07251E88BCB5',
  },
  vLiteSwap: {
    // 42161: '0x3d29e161ce07369EC9418790718c75a07bAbDa1a',
    42161: '0x554cd73267BB3322cfC7dAD97d6340dAc6408400',
    421613: '0xeE8Ba8E227f664E04123f7B9459cAC5Bc86D48C4',
  },
  vLiteNewSwap: {
    42161: {
      // LP
      '0x38e835c74b8d82cd8ba33afaa692b4d43c07e889': '0xC8bF0Bab9d283c0B233b742093bA5da4499B6865',
      // LITE
      '0x691168c8df23faeab4df89d823f3ea56ba5c3ebc': '0x4632B734Ec5BfB8E6132247D851854787b916B37',
    },
    421613: {
      // LITE(小写) => 合约
      '0x93efaa395f773976c24394c40191b32be6adcaf6': '0x1CBd112025EAE15F38f52A552DFa1f399A6910f9',
      // LP(小写) => 合约
      '0x6db02ab250387ab72a2ec329da819c41abe79324': '0x3647b46b93B113AA62815b5Fb9B811E1Eb3143eF',
    },
  },
  vLiteStake: {
    42161: '0x398CC1Aaa46cf00875A3c1e104a67af0Da280F5b',
    421613: '0x6e0B3037d670B544099fa9094d12fe8806a90B89',
  },
}
