import BigNumber from 'bignumber.js'
import multicall from 'utils/multicall'
import idoAbi from 'config/abi/idoWithInvite.json'
import icoAbi from 'config/abi/ico2.json'
import addresses from 'config/constants/contracts'

export const fetchArbLitePublicData = async (chainId: number) => {
  try {
    const calls = [
      'sharesPrice',
      'sharesAmount',
      'userMaxQuota',
      'totalBuyAmount',
      'sharesStartTime',
      'sharesEndTime',
      'sharesUnlockTime',
      'isEmergencyRefund',
    ].map((method) => ({
      address: addresses.launchpadArbLite[chainId],
      name: method,
    }))

    const [
      [tokenPrice],
      [tokenSupply],
      [userMaxQuota],
      [usdtTotal],
      [startTime],
      [endedAt],
      [getTokenAt],
      [isEmergencyRefund],
    ] = await multicall(idoAbi, calls, chainId)
    return {
      tokenPrice: new BigNumber(tokenPrice.toString()).toJSON(),
      tokenSupply: new BigNumber(tokenSupply.toString()).toJSON(),
      userMaxQuota: new BigNumber(userMaxQuota.toString()).toJSON(),
      usdtTotal: new BigNumber(usdtTotal.toString()).toJSON(),
      startTime: startTime.toNumber(),
      endedAt: endedAt.toNumber(),
      getTokenAt: getTokenAt.toNumber(),
      isEmergencyRefund,
    }
  } catch (error) {
    return {
      tokenPrice: '0',
      tokenSupply: '0',
      userMaxQuota: '0',
      usdtTotal: '0',
      startTime: 0,
      endedAt: 0,
      getTokenAt: 0,
      isEmergencyRefund: false,
    }
  }
}

export const fetchArbLiteUserData = async (chainId: number, account: string) => {
  try {
    const calls = ['getUserQuota', 'userBuyOf', 'lastUnlockAt', 'inviteCount', 'inviteRewardOf'].map((method) => ({
      address: addresses.launchpadArbLite[chainId],
      name: method,

      params: [account],
    }))

    const [[userAmount], [userTotal], [userDone], [inviteCount], [inviteRewardOf]] = await multicall(
      idoAbi,
      calls,
      chainId,
    )

    return {
      userAmount: new BigNumber(userAmount.toString()).toJSON(),
      userTotal: new BigNumber(userTotal.toString()).toJSON(),
      userDone: Boolean(userDone.toNumber()),
      inviteCount: inviteCount.toNumber(),
      inviteRewardOf: new BigNumber(inviteRewardOf.toString()).toJSON(),
    }
  } catch (error) {
    return {
      userAmount: '0',
      userTotal: '0',
      userDone: false,
      inviteCount: 0,
      inviteRewardOf: '0',
    }
  }
}

export const fetchArbLiteIcoPublicData = async (chainId: number) => {
  try {
    const calls = [
      'sharesPrice',
      'sharesAmount',
      'totalBuyAmount',
      'sharesStartTime',
      'sharesEndTime',
      'sharesUnlockTime',
    ].map((method) => ({
      address: addresses.launchpadArbLiteIco[chainId],
      name: method,
    }))

    const [[tokenPrice], [tokenSupply], [usdtTotal], [endedAt], [getTokenAt], [unlockTime]] = await multicall(
      icoAbi,
      calls,
      chainId,
    )

    return {
      tokenPrice: new BigNumber(tokenPrice.toString()).toJSON(),
      tokenSupply: new BigNumber(tokenSupply.toString()).toJSON(),
      usdtTotal: new BigNumber(usdtTotal.toString()).toJSON(),
      endedAt: endedAt.toNumber(),
      getTokenAt: getTokenAt.toNumber(),
      unlockTime: unlockTime.toNumber(),
    }
  } catch (error) {
    return {
      tokenPrice: '0',
      tokenSupply: '0',
      usdtTotal: '0',
      endedAt: 0,
      getTokenAt: 0,
      unlockTime: 0,
    }
  }
}

export const fetchArbLiteIcoUserData = async (chainId: number, account: string) => {
  try {
    const calls = ['whiteList', 'getUserQuota', 'userUnlockOf'].map((method) => ({
      address: addresses.launchpadArbLiteIco[chainId],
      name: method,

      params: [account],
    }))

    const [[whiteAmount], [userAmount], [unlockAmount]] = await multicall(icoAbi, calls, chainId)

    return {
      whiteAmount: new BigNumber(whiteAmount.toString()).toJSON(),
      userAmount: new BigNumber(userAmount.toString()).toJSON(),
      unlockAmount: new BigNumber(unlockAmount.toString()).toJSON(),
    }
  } catch (error) {
    return {
      whiteAmount: '0',
      userAmount: '0',
      unlockAmount: '0',
    }
  }
}
