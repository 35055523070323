import { parseUnits } from '@ethersproject/units'
import { createSlice, configureStore } from '@reduxjs/toolkit'
import { Field } from 'state/stableMint/actions'
import { MintExtState } from 'state/types'
import BigNumber from 'bignumber.js'
import { Currency } from '../../../packages/swap-sdk/src/entities'
import { fetchAmount, fetchLimit } from './fetch'

const initialState: MintExtState = {
  mintDayLimit: '0',
  mintHourLimit: '0',
  mintMaxLimit: '0',
  burnDayLimit: '0',
  burnHourLimit: '0',
  burnMaxLimit: '0',
  outAmount: '0',
}

export const fetchLimitAsync = (chainId: number) => async (dispatch) => {
  try {
    const limit = await fetchLimit(chainId)
    dispatch(setLimit(limit))
  } catch (e) {
    console.log('fetchLimitAsync=====', e)
  }
}

export const fetchAmountAsync =
  (chainId: number, currencies: { [field in Field]?: Currency }, inAmount: string) => async (dispatch) => {
    try {
      const { amount } = await fetchAmount(chainId, currencies, inAmount)
      dispatch(setOutAmount(amount.toJSON()))
    } catch (e) {
      dispatch(setOutAmount('0'))
      console.log('fetchAmountAsync=====', currencies, e)
    }
  }

export const slice = createSlice({
  name: 'stableMintExt',
  initialState,
  reducers: {
    setLimit: (state, action) => {
      const { mintDayLimit, mintHourLimit, mintMaxLimit, burnDayLimit, burnHourLimit, burnMaxLimit } = action.payload

      state.mintDayLimit = mintDayLimit
      state.mintHourLimit = mintHourLimit
      state.mintMaxLimit = mintMaxLimit
      state.burnDayLimit = burnDayLimit
      state.burnHourLimit = burnHourLimit
      state.burnMaxLimit = burnMaxLimit
    },
    setOutAmount: (state, action) => {
      const { payload } = action
      state.outAmount = payload
    },
  },
})

export const { setLimit, setOutAmount } = slice.actions

export default slice.reducer
