import BigNumber from 'bignumber.js'
import fromPairs from 'lodash/fromPairs'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
import erc20ABI from 'config/abi/erc20.json'
import sugarPoolAbi from 'config/abi/sugarPool.json'
import routerABI from 'config/abi/router.json'
import pairABI from 'config/abi/pair.json'
import multicall, { multicallv2 } from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import chunk from 'lodash/chunk'
import { SerializedPoolConfig } from 'config/constants/types'
import { USDT } from '@pancakeswap/tokens'
import { ROUTER_ADDRESS } from 'config/constants/exchange'
import { getBalanceAmount, getDecimalAmount } from 'utils/formatBalance'
import { FACTORY_ADDRESS_MAP } from '@pancakeswap/sdk'

// 获取糖浆池的开始区块高度，分红区块高度
export const fetchPoolsBlockLimits = async (data: SerializedPoolConfig[], chainId) => {
  const poolsWithEnd = data.filter((p) => p.sousId !== 0)
  
  const startEndBlockCalls = poolsWithEnd.flatMap((poolConfig) => {
    return [
      {
        address: getAddress(poolConfig.contractAddress, chainId),
        name: 'startBlock',
      },
      {
        address: getAddress(poolConfig.contractAddress, chainId),
        name: 'bonusEndBlock',
      },
    ]
  })
  const startEndBlockRaw = await multicall(sugarPoolAbi, startEndBlockCalls, chainId)
  const startEndBlockResult = startEndBlockRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return poolsWithEnd.map((cakePoolConfig, index) => {
    const [[startBlock], [endBlock]] = startEndBlockResult[index]
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: startBlock.toNumber(),
      endBlock: endBlock.toNumber(),
    }
  })
}

// 获取质押池质押的币种质押的数量
export const fetchPoolsTotalStaking = async (data: SerializedPoolConfig[], chainId) => {
  const poolsBalanceOf = data.map((poolConfig) => {
    return {
      address: poolConfig.stakingToken.address,
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress, chainId)],
    }
  })
  const poolsTotalStaked = await multicall(erc20ABI, poolsBalanceOf, chainId)

  return data.map((p, index) => ({
    sousId: p.sousId,
    totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
  }))
}

export const fetchPairAddress =async (data: SerializedPoolConfig[], chainId) => {
  const usdtTokenAddress = USDT[chainId].address;
  const calls = data.flatMap((poolConfig) => {
    const {stakingToken: {address: stakeTokenAddress}, earningToken: {address: earningTokenAddress}} = poolConfig
    const isStakeusdt = stakeTokenAddress.toLowerCase() === usdtTokenAddress.toLowerCase();
    if(isStakeusdt || usdtTokenAddress.toLowerCase() === earningTokenAddress.toLowerCase()){
      const tokenAddress = isStakeusdt ? earningTokenAddress : stakeTokenAddress;
      return [{
        address: FACTORY_ADDRESS_MAP[chainId],
        name: 'getPair',
        params: [tokenAddress, usdtTokenAddress],
      },{
        address: FACTORY_ADDRESS_MAP[chainId],
        name: 'getPair',
        params: [tokenAddress, usdtTokenAddress],
      }]
    }
    return [{
      address: FACTORY_ADDRESS_MAP[chainId],
      name: 'getPair',
      params: [stakeTokenAddress, usdtTokenAddress],
    },
    {
      address: FACTORY_ADDRESS_MAP[chainId],
      name: 'getPair',
      params: [earningTokenAddress, usdtTokenAddress],
    }
  ]
  })

  const pairRaw = await multicall(pairABI, calls, chainId)
  const pairResult = pairRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)
    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
  return data.map((poolConfig, index) => {
    const {stakingToken: {decimals: stakeDecimal, address: stakeTokenAddress}, earningToken: {decimals: earnDecimal, address: earningTokenAddress}} = poolConfig
    const [[stakePair], [earnPair]] = pairResult[index]
    return {
      sousId: poolConfig.sousId,
      stakePair,
      earnPair,
      stakeDecimal,
      earnDecimal,
      stakeTokenAddress,
      earningTokenAddress
    }
  })
}

export const fetchPoolsPrices = async (data, chainId) => {
  const usdtTokenAddress = USDT[chainId].address;
  const priceCalls = data.flatMap((poolConfig) => {

    const {stakeTokenAddress, earningTokenAddress, stakeDecimal, earnDecimal} = poolConfig;
    const stakeAmount = getDecimalAmount(new BigNumber(1), stakeDecimal).toFixed()
    const earnAmount = getDecimalAmount(new BigNumber(1), earnDecimal).toFixed()
    const isStakeusdt = stakeTokenAddress.toLowerCase() === usdtTokenAddress.toLowerCase();
    if(isStakeusdt || usdtTokenAddress.toLowerCase() === earningTokenAddress.toLowerCase()){
      const amount = isStakeusdt ? earnAmount : stakeAmount
      const tokenA = isStakeusdt ? earningTokenAddress : stakeTokenAddress
      return [
        {
          address: ROUTER_ADDRESS[chainId],
          name: 'getAmountsOut',
          params: [amount, [tokenA, usdtTokenAddress]],
        },
        {
          address: ROUTER_ADDRESS[chainId],
          name: 'getAmountsOut',
          params: [amount, [tokenA, usdtTokenAddress]],
        }]
    } 
    return [
      {
        address: ROUTER_ADDRESS[chainId],
        name: 'getAmountsOut',
        params: [stakeAmount, [stakeTokenAddress, usdtTokenAddress]],
      },
      {
        address: ROUTER_ADDRESS[chainId],
        name: 'getAmountsOut',
        params: [earnAmount, [earningTokenAddress, usdtTokenAddress]],
      },
    ]
  })
  const pricesRaw = await multicall(routerABI, priceCalls, chainId)
  const pricesResult = pricesRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return data.map((poolConfig, index) => {
    // const stakeTokenAddress = poolConfig.stakingToken.address;
    // const earningTokenAddress = poolConfig.earningToken.address;
    const {stakeTokenAddress, earningTokenAddress, stakeDecimal, earnDecimal} = poolConfig;
    if(usdtTokenAddress.toLowerCase() === stakeTokenAddress.toLowerCase()){
      const [[earnPrice]] = pricesResult[index]
      const  earningTokenPrice =  getBalanceAmount([earnPrice[1]] as any, earnDecimal).toFixed()
      return {
        sousId: poolConfig.sousId,
        stakingTokenPrice: 1,
        earningTokenPrice
      }
    }
    if(usdtTokenAddress.toLowerCase() === earningTokenAddress.toLocaleLowerCase()){
      const [[stakePrice]] = pricesResult[index]
      const  stakingTokenPrice =  getBalanceAmount([stakePrice[1]] as any, stakeDecimal).toFixed()
      return {
        sousId: poolConfig.sousId,
        stakingTokenPrice,
        earningTokenPrice: 1
      }
    }
    const [[stakePrice], [earnPrice]] = pricesResult[index]
    const stakingTokenPrice =  getBalanceAmount([stakePrice[1]] as any, stakeDecimal).toFixed()
    const  earningTokenPrice =  getBalanceAmount([earnPrice[1]] as any, earnDecimal).toFixed()
    return {
      sousId: poolConfig.sousId,
      stakingTokenPrice,
      earningTokenPrice
    }
  })
}

export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
  data,
  chainId
): Promise<{ [key: string]: { stakingLimit: BigNumber; numberBlocksForUserLimit: number } }> => {
  const validPools = data
    .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
    .filter((p) => !poolsWithStakingLimit.includes(p.sousId))

  // Get the staking limit for each valid pool
  const poolStakingCalls = validPools
    .map((validPool) => {
      const contractAddress = getAddress(validPool.contractAddress, chainId)
      return ['hasUserLimit', 'poolLimitPerUser', 'numberBlocksForUserLimit'].map((method) => ({
        address: contractAddress,
        name: method,
      }))
    })
    .flat()

  const poolStakingResultRaw = await multicallv2({
    abi: sugarPoolAbi,
    calls: poolStakingCalls,
    options: { requireSuccess: false },
    chainId
  })
  const chunkSize = poolStakingCalls.length / validPools.length
  const poolStakingChunkedResultRaw = chunk(poolStakingResultRaw.flat(), chunkSize)
  return fromPairs(
    poolStakingChunkedResultRaw.map((stakingLimitRaw, index) => {
      const hasUserLimit = stakingLimitRaw[0]
      const stakingLimit = hasUserLimit && stakingLimitRaw[1] ? new BigNumber(stakingLimitRaw[1].toString()) : BIG_ZERO
      const numberBlocksForUserLimit = stakingLimitRaw[2] ? (stakingLimitRaw[2] as EthersBigNumber).toNumber() : 0
      return [validPools[index].sousId, { stakingLimit, numberBlocksForUserLimit }]
    }),
  )
}

// 获取V3版本pancakeProfileIsRequested, pancakeProfileThresholdPoints属性值
export const fetchPoolsProfileRequirement = async (data, chainId): Promise<{
  [key: string]: {
    required: boolean
    thresholdPoints: string
  }
}> => {
  const poolsWithV3 = data.filter((pool) => pool?.version === 3)
  const poolProfileRequireCalls = poolsWithV3
    .map((validPool) => {
      const contractAddress = getAddress(validPool.contractAddress, chainId)
      return ['profileIsRequested', 'profileThresholdPoints'].map((method) => ({
        address: contractAddress,
        name: method,
      }))
    })
    .flat()

  const poolProfileRequireResultRaw = await multicallv2({
    abi: sugarPoolAbi,
    calls: poolProfileRequireCalls,
    options: { requireSuccess: false },
    chainId
  })
  const chunkSize = poolProfileRequireCalls.length / poolsWithV3.length
  const poolStakingChunkedResultRaw = chunk(poolProfileRequireResultRaw.flat(), chunkSize)
  return fromPairs(
    poolStakingChunkedResultRaw.map((poolProfileRequireRaw, index) => {
      const hasProfileRequired = poolProfileRequireRaw[0]
      const profileThresholdPoints = poolProfileRequireRaw[1]
        ? new BigNumber(poolProfileRequireRaw[1].toString())
        : BIG_ZERO
      return [
        poolsWithV3[index].sousId,
        {
          required: !!hasProfileRequired,
          thresholdPoints: profileThresholdPoints.toJSON(),
        },
      ]
    }),
  )
}

