import BigNumber from 'bignumber.js'
import multicall from 'utils/multicall'
import airdropAbi from 'config/abi/airdrop.json'
import airdropSplitAbi from 'config/abi/airdropSplit.json'
import addresses from 'config/constants/contracts'
import { AddressZero } from '@ethersproject/constants'

export const fetchPublicData = async (chainId: number) => {
  try {
    const calls = ['maxPendingDay', 'pool'].map((method) => ({
      address: addresses.airdrop[chainId],
      name: method,
    }))

    const [[maxPendingDay], pool] = await multicall(airdropAbi, calls, chainId)

    return {
      maxPendingDay: maxPendingDay.toNumber(),
      totalStaked: new BigNumber(pool.totalStaked.toString()).toJSON(),
    }
  } catch (error) {
    return {
      maxPendingDay: 0,
      totalStaked: '0',
    }
  }
}

export const fetchUserData = async (chainId: number, account: string) => {
  try {
    const calls = ['userInfo'].map((method) => ({
      address: addresses.airdrop[chainId],
      name: method,
      params: [account],
    }))

    const [userInfo] = await multicall(airdropAbi, calls, chainId)

    return {
      lastUpdateAt: userInfo.lastUpdateAt.toNumber(),
      rewardOf: new BigNumber(userInfo.rewardOf.toString()).toJSON(),
      stakedOf: new BigNumber(userInfo.stakedOf.toString()).toJSON(),
      userReward: new BigNumber(userInfo.userReward.toString()).toJSON(),
      withdrawReward: new BigNumber(userInfo.withdrawReward.toString()).toJSON(),
    }
  } catch (error) {
    return {
      lastUpdateAt: 0,
      rewardOf: '0',
      stakedOf: '0',
      userReward: '0',
      withdrawReward: '0',
    }
  }
}

export const fetchSplitPublicData = async (chainId: number) => {
  try {
    const calls = ['tokenAddress', 'totalShares', 'ticketPrice', 'totalReleased', 'endAt'].map((method) => ({
      address: addresses.airdropSplit[chainId],
      name: method,
    }))

    const [[tokenAddress], [totalShares], [ticketPrice], [totalReleased], [endAt]] = await multicall(
      airdropSplitAbi,
      calls,
      chainId,
    )

    return {
      tokenAddress: tokenAddress.toString(),
      totalShares: new BigNumber(totalShares.toString()).toJSON(),
      ticketPrice: new BigNumber(ticketPrice.toString()).toJSON(),
      totalReleased: new BigNumber(totalReleased.toString()).toJSON(),
      endAt: endAt.toNumber(),
    }
  } catch (error) {
    return {
      tokenAddress: AddressZero,
      totalShares: '0',
      ticketPrice: '0',
      totalReleased: '0',
      endAt: 0,
    }
  }
}

export const fetchSplitUserData = async (chainId: number, account: string) => {
  try {
    const calls = ['shares', 'released', 'releasable'].map((method) => ({
      address: addresses.airdropSplit[chainId],
      name: method,
      params: [account],
    }))

    const [[shares], [released], [releasable]] = await multicall(airdropSplitAbi, calls, chainId)

    return {
      shares: new BigNumber(shares.toString()).toJSON(),
      released: new BigNumber(released.toString()).toJSON(),
      releasable: new BigNumber(releasable.toString()).toJSON(),
    }
  } catch (error) {
    return {
      shares: '0',
      released: '0',
      releasable: '0',
    }
  }
}

export const fetchSplit2PublicData = async (chainId: number) => {
  try {
    const calls = ['tokenAddress', 'totalShares', 'ticketPrice', 'totalReleased', 'endAt', 'tokenShares'].map(
      (method) => ({
        address: addresses.airdropSplit2[chainId],
        name: method,
      }),
    )

    const [[tokenAddress], [totalShares], [ticketPrice], [totalReleased], [endAt], [tokenShares]] = await multicall(
      airdropSplitAbi,
      calls,
      chainId,
    )

    return {
      tokenAddress: tokenAddress.toString(),
      totalShares: new BigNumber(totalShares.toString()).toJSON(),
      ticketPrice: new BigNumber(ticketPrice.toString()).toJSON(),
      totalReleased: new BigNumber(totalReleased.toString()).toJSON(),
      endAt: endAt.toNumber(),
      tokenShares: new BigNumber(tokenShares.toString()).toJSON(),
    }
  } catch (error) {
    return {
      tokenAddress: AddressZero,
      totalShares: '0',
      ticketPrice: '0',
      totalReleased: '0',
      tokenShares: '0',
      endAt: 0,
    }
  }
}

export const fetchSplit2UserData = async (chainId: number, account: string) => {
  try {
    const calls = ['shares', 'released', 'releasable'].map((method) => ({
      address: addresses.airdropSplit2[chainId],
      name: method,
      params: [account],
    }))

    const [[shares], [released], [releasable]] = await multicall(airdropSplitAbi, calls, chainId)

    return {
      shares: new BigNumber(shares.toString()).toJSON(),
      released: new BigNumber(released.toString()).toJSON(),
      releasable: new BigNumber(releasable.toString()).toJSON(),
    }
  } catch (error) {
    return {
      shares: '0',
      released: '0',
      releasable: '0',
    }
  }
}
