import {
  MenuItemsType,
  TradeIcon,
  TradeFilledIcon,
  WinIcon,
  WinFillIcon,
  StableIcon,
  StableFillIcon,
  XliteMenuIcon,
  XliteMenuFillIcon,
  MoreIcon,
  GameFillIcon,
  GameIcon,
  BridgeIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { DropdownMenuItems, DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'
import { ChainId } from '../../../../packages/swap-sdk/src/constants'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (t: ContextApi['t'], isDark: boolean, languageCode?: string, chainId?: number) => ConfigMenuItemsType[] =
  (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Trade'),
        icon: TradeIcon,
        fillIcon: TradeFilledIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [
          {
            label: t('Swap'),
            href: '/swap',
          },
          {
            label: t('Liquidity'),
            href: '/liquidity',
          },
        ],
      },
      {
        label: t('Govern'),
        icon: XliteMenuIcon,
        fillIcon: XliteMenuFillIcon,
        href: '/xlite',
        showItemsOnMobile: false,
        supportChainIds: [ChainId.BSC],
        items: [
          {
            label: t('Govern'),
            href: '/xlite',
          },
          {
            label: t('Dividends'),
            href: '/xlite/dividends',
            supportChainIds: [ChainId.BSC],
          },
          {
            label: t('Forum'),
            href: '#',
            supportChainIds: [ChainId.BSC],
          },
          {
            label: t('Voting'),
            href: '#',
            supportChainIds: [ChainId.BSC],
          },
          {
            label: t('Launchpad'),
            href: '/xlite/launchpad',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Govern'),
        icon: XliteMenuIcon,
        fillIcon: XliteMenuFillIcon,
        href: '/vlite',
        showItemsOnMobile: false,
        supportChainIds: [ChainId.ARBITRUM],
        items: [
          {
            label: t('Govern'),
            href: '/vlite',
            supportChainIds: [ChainId.ARBITRUM],
          },
          {
            label: t('Dividends'),
            href: '/vlite/dividends',
            supportChainIds: [ChainId.ARBITRUM],
          },
          // {
          //   label: t('Launchpad'),
          //   href: '/vlite/launchpad',
          //   supportChainIds: [ChainId.ARBITRUM],
          // },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Merge'),
        icon: WinIcon,
        fillIcon: WinFillIcon,
        href: '/merge',
        showOnMobile: false,
        showItemsOnMobile: false,
        items: [],
        supportChainIds: [ChainId.BSC],
      },
      {
        label: 'LUSD',
        icon: StableIcon,
        fillIcon: StableFillIcon,
        href: '/mint',
        showItemsOnMobile: false,
        supportChainIds: [ChainId.BSC],
        items: [
          {
            label: t('Mint'),
            href: '/mint',
          },
          {
            label: `${t('Redeem')}`,
            href: '/burn',
          },
        ],
      },
      {
        label: t('Bridge'),
        icon: BridgeIcon,
        fillIcon: BridgeIcon,
        hideSubNav: true,
        items: [
          {
            label: 'Chainge',
            href: 'https://dapp.chainge.finance/?fromChain=BSC&toChain=ARB&fromToken=USDT&toToken=USDT',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: 'Arbitrum',
            href: 'https://bridge.arbitrum.io/?l2ChainId=42161',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ],
      },
      {
        label: t('Launchpad'),
        icon: GameIcon,
        fillIcon: GameFillIcon,
        href: '/launchpad',
        showItemsOnMobile: false,
        supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET, ChainId.ARBITRUM, ChainId.ARBITRUM_TEST],
        items: [
          // {
          //   label: t('Stake'),
          //   href: '/stable',
          // },
          // {
          //   label: `${t('Psm')}`,
          //   href: '/psm',
          // },
          // {
          //   label: `${t('Mint')}`,
          //   href: '/mint',
          // },
        ],
      },
      // {
      //   label: t('Bridge'),
      //   icon: BridgeIcon,
      //   fillIcon: BridgeIcon,
      //   hideSubNav: true,
      //   items: [
      //     {
      //       label: 'Chainge',
      //       href: 'https://dapp.chainge.finance/?fromChain=BSC&toChain=ARB&fromToken=USDT&toToken=USDT',
      //       type: DropdownMenuItemType.EXTERNAL_LINK,
      //     },
      //     {
      //       label: 'Arbitrum',
      //       href: 'https://bridge.arbitrum.io/?l2ChainId=42161',
      //       type: DropdownMenuItemType.EXTERNAL_LINK,
      //     },
      //   ],
      // },
      {
        label: t('Airdrop'),
        icon: WinIcon,
        fillIcon: WinFillIcon,
        href: '/airdrop',
        showOnMobile: false,
        showItemsOnMobile: false,
        items: [],
        supportChainIds: [ChainId.ARBITRUM, ChainId.ARBITRUM_TEST],
      },
      {
        label: '',
        icon: MoreIcon,
        hideSubNav: true,
        // href: '/liquidity',
        // showItemsOnMobile: false,
        items: [
          {
            label: t('Merge'),
            // icon: WinIcon,
            // fillIcon: WinFillIcon,
            href: '/merge',
            // showItemsOnMobile: false,
            // items: [],
            supportChainIds: [ChainId.BSC],
            // hideSubNav: false
          },
          {
            label: 'Dex Screener',
            href: 'https://dexscreener.com/bsc/0xc81f26c1aa51e75c9def1a9fa951245a9cd7ad66',
            type: DropdownMenuItemType.EXTERNAL_LINK,
            supportChainIds: [ChainId.BSC],
          },
          {
            label: 'Dex Screener',
            href: 'https://dexscreener.com/arbitrum/0x38e835c74b8d82cd8ba33afaa692b4d43c07e889',
            type: DropdownMenuItemType.EXTERNAL_LINK,
            supportChainIds: [ChainId.ARBITRUM],
          },
          {
            label: 'LitePay',
            href: 'https://download.liteusd.com/#/download',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: 'CoinMarketCap',
            href: 'https://coinmarketcap.com/currencies/lite-usd/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: 'CoinGecko',
            href: 'https://www.coingecko.com/en/coins/liteusd',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
