import { getAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import liteStakeABI from 'config/abi/liteStake.json'
import erc20ABI from 'config/abi/erc20.json'
import routerABI from 'config/abi/router.json'
import BigNumber from 'bignumber.js'
import chunk from 'lodash/chunk'
import { SerializedStake } from 'state/types'
import { USDT } from '@pancakeswap/tokens'
import { FACTORY_ADDRESS_MAP } from '@pancakeswap/sdk'
import pairABI from 'config/abi/pair.json'
import { getBalanceAmount, getDecimalAmount } from 'utils/formatBalance'
import { ROUTER_ADDRESS } from 'config/constants/exchange'

export const fetchPoolId = async (config: SerializedStake[], chainId) => {
  const calls = config.flatMap((pool) => {
    return [
      {
        address: getAddress(pool.contractAddress, chainId),
        name: 'totalAllocPoint',
      },
      {
        address: getAddress(pool.contractAddress, chainId),
        name: 'getPendingReward',
      },
      {
        address: getAddress(pool.contractAddress, chainId),
        name: 'unstakeDuration',
      },
    ]
  })

  const chunkSize = calls.length / config.length
  const poolsIdsCallsResult = await multicall(liteStakeABI, calls, chainId)
  const poolsIdsChunk: any[] = chunk(poolsIdsCallsResult, chunkSize)

  return config.map((p, index) => {
    const [totalPoint, pendingReward, unstakeDuration] = poolsIdsChunk[index]
    return {
      sousId: p.sousId,
      totalPoint: new BigNumber(totalPoint),
      pendingReward: new BigNumber(pendingReward),
      unstakeDuration: new BigNumber(unstakeDuration as any).toJSON(),
    }
  })
}

export const fetchPoolInfo = async (config: SerializedStake[], chainId) => {
  const calls = config.map((p) => {
    return {
      address: getAddress(p.contractAddress, chainId),
      name: 'poolInfo',
      params: [p.poolId],
    }
  })
  const poolInfoCallsResult = await multicall(liteStakeABI, calls, chainId)
  return config.map((p, index) => {
    const totalStaked = new BigNumber([poolInfoCallsResult[index].totalStaked] as any)
    const allocPoint = new BigNumber([poolInfoCallsResult[index].allocPoint] as any)
    return {
      sousId: p.sousId,
      totalStaked,
      allocPoint,
    }
  })
}

export const fetchPairAddress = async (data: SerializedStake[], chainId) => {
  const usdtTokenAddress = USDT[chainId].address
  const calls = data.flatMap((poolConfig) => {
    const {
      earningToken: { address: earningTokenAddress },
    } = poolConfig
    // const isEarnUsdt = earningTokenAddress.toLowerCase() === usdtTokenAddress.toLowerCase();
    // if(isEarnUsdt){
    //   // const tokenAddress = isStakeusdt ? earningTokenAddress : stakeTokenAddress;

    // }
    return [
      {
        address: FACTORY_ADDRESS_MAP[chainId],
        name: 'getPair',
        params: [earningTokenAddress, usdtTokenAddress],
      },
    ]
    // return [{
    //   address: FACTORY_ADDRESS_MAP[chainId],
    //   name: 'getPair',
    //   params: [stakeTokenAddress, usdtTokenAddress],
    // },
    // {
    //   address: FACTORY_ADDRESS_MAP[chainId],
    //   name: 'getPair',
    //   params: [earningTokenAddress, usdtTokenAddress],
    // }]
  })

  const pairRaw = await multicall(pairABI, calls, chainId)
  const pairResult = pairRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 1)
    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
  return data.map((poolConfig, index) => {
    const {
      stakingToken: { decimals: stakeDecimal, address: stakeTokenAddress },
      earningToken: { decimals: earnDecimal, address: earningTokenAddress },
    } = poolConfig
    const [[earnPair]] = pairResult[index]
    return {
      sousId: poolConfig.sousId,
      // stakePair,
      earnPair,
      // stakeDecimal,
      earnDecimal,
      // stakeTokenAddress,
      earningTokenAddress,
    }
  })
}

export const fetchPoolsPrices = async (data, chainId) => {
  const usdtTokenAddress = USDT[chainId].address
  const priceCalls = data.flatMap((poolConfig) => {
    const { earningTokenAddress, earnDecimal } = poolConfig
    // const stakeAmount = getDecimalAmount(new BigNumber(1), stakeDecimal).toFixed()
    const earnAmount = getDecimalAmount(new BigNumber(1), earnDecimal).toFixed()
    // const isStakeusdt = stakeTokenAddress.toLowerCase() === usdtTokenAddress.toLowerCase();
    // if(isStakeusdt || usdtTokenAddress.toLowerCase() === earningTokenAddress.toLowerCase()){
    //   const amount = isStakeusdt ? earnAmount : stakeAmount
    //   const tokenA = isStakeusdt ? earningTokenAddress : stakeTokenAddress
    //   return [
    //     {
    //       address: ROUTER_ADDRESS[chainId],
    //       name: 'getAmountsOut',
    //       params: [amount, [tokenA, usdtTokenAddress]],
    //     },
    //     {
    //       address: ROUTER_ADDRESS[chainId],
    //       name: 'getAmountsOut',
    //       params: [amount, [tokenA, usdtTokenAddress]],
    //     }]
    // }
    return [
      {
        address: ROUTER_ADDRESS[chainId],
        name: 'getAmountsOut',
        params: [earnAmount, [earningTokenAddress, usdtTokenAddress]],
      },
    ]
  })
  const pricesRaw = await multicall(routerABI, priceCalls, chainId)
  const pricesResult = pricesRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 1)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return data.map((poolConfig, index) => {
    // const stakeTokenAddress = poolConfig.stakingToken.address;
    // const earningTokenAddress = poolConfig.earningToken.address;
    const { stakeTokenAddress, earningTokenAddress, stakeDecimal, earnDecimal } = poolConfig
    // if(usdtTokenAddress.toLowerCase() === stakeTokenAddress.toLowerCase()){
    //   const [[earnPrice]] = pricesResult[index]
    //   const  earningTokenPrice =  getBalanceAmount([earnPrice[1]] as any, earnDecimal).toFixed()
    //   return {
    //     sousId: poolConfig.sousId,
    //     // stakingTokenPrice: 1,
    //     earningTokenPrice
    //   }
    // }
    // if(usdtTokenAddress.toLowerCase() === earningTokenAddress.toLocaleLowerCase()){
    //   const [[stakePrice]] = pricesResult[index]
    //   const  stakingTokenPrice =  getBalanceAmount([stakePrice[1]] as any, stakeDecimal).toFixed()
    //   return {
    //     sousId: poolConfig.sousId,
    //     stakingTokenPrice,
    //     earningTokenPrice: 1
    //   }
    // }
    const [[earnPrice]] = pricesResult[index]

    // const stakingTokenPrice =  getBalanceAmount([stakePrice[1]] as any, stakeDecimal).toFixed()
    const earningTokenPrice = getBalanceAmount([earnPrice[1]] as any, earnDecimal).toFixed()
    return {
      sousId: poolConfig.sousId,
      // stakingTokenPrice,
      earningTokenPrice,
    }
  })
}

export const fetchUserInfo = async (config: SerializedStake[], chainId, account) => {
  const calls = config.flatMap((pool) => {
    // FIXME: 由于nlite质押合约升级增加的参数, 其他质押跳过读取数据
    if (
      chainId === 56 &&
      pool.contractAddress[chainId].toLowerCase() !== '0xec2c096152e4a3f556c714c4923b8e15fe7cd211'
    ) {
      return [
        {
          address: getAddress(pool.contractAddress, chainId),
          name: 'userInfo',
          params: [pool.poolId, account],
        },
        {
          address: getAddress(pool.contractAddress, chainId),
          name: 'rewardAmount',
          params: [account, pool.poolId],
        },
      ]
    }

    return [
      {
        address: getAddress(pool.contractAddress, chainId),
        name: 'userInfo',
        params: [pool.poolId, account],
      },
      {
        address: getAddress(pool.contractAddress, chainId),
        name: 'rewardAmount',
        params: [account, pool.poolId],
      },
      {
        address: getAddress(pool.contractAddress, chainId),
        name: 'userUnstakeAmount',
        params: [pool.poolId, account],
      },
      {
        address: getAddress(pool.contractAddress, chainId),
        name: 'userUnstakeAt',
        params: [pool.poolId, account],
      },
    ]
  })

  const chunkSize = calls.length / config.length
  const poolsIdsCallsResult = await multicall(liteStakeABI, calls, chainId)
  const poolsIdsChunk: any[] = chunk(poolsIdsCallsResult, chunkSize)

  return config.map((p, index) => {
    const [userInfo, rewardAmount, userUnstakeAmount, userUnstakeAt]: any[] = poolsIdsChunk[index]
    return {
      sousId: p.sousId,
      data: {
        stakedBalance: new BigNumber([userInfo._user.stakedOf] as any),
        stakeAt: new BigNumber([userInfo._user.lastDepositAt] as any).toJSON(),
        userReward: new BigNumber([userInfo._user.userReward] as any),
        pendingReward: new BigNumber(rewardAmount as any).toJSON(),
        unstakeAmount: new BigNumber([userUnstakeAmount] as any),
        unstakeAt: new BigNumber(userUnstakeAt as any).toJSON(),
      },
    }
  })
}

export const fetchErc20 = async (config: SerializedStake[], chainId, account) => {
  const calls = config.flatMap((pool) => {
    return [
      {
        address: pool.stakingToken.address,
        name: 'allowance',
        params: [account, getAddress(pool.contractAddress, chainId)],
      },
      {
        address: pool.stakingToken.address,
        name: 'balanceOf',
        params: [account],
      },
    ]
  })

  const chunkSize = calls.length / config.length
  const poolsIdsCallsResult = await multicall(erc20ABI, calls, chainId)
  const poolsIdsChunk: any[] = chunk(poolsIdsCallsResult, chunkSize)

  return config.map((p, index) => {
    const [allowance, balanceOf]: any[] = poolsIdsChunk[index]
    return {
      sousId: p.sousId,
      data: {
        allowance: new BigNumber(allowance as any).toJSON(),
        stakingTokenBalance: new BigNumber(balanceOf as any),
      },
    }
  })
}

export const fetchErc20Lp = async (config: SerializedStake[], chainId) => {
  const calls = config.flatMap((pool) => {
    return [
      {
        address: pool.stakingToken.address,
        name: 'totalSupply',
        params: [],
      },
      {
        address: pool.earningToken.address,
        name: 'balanceOf',
        params: [pool.stakingToken.address],
      },
    ]
  })

  const chunkSize = calls.length / config.length
  const poolsIdsCallsResult = await multicall(erc20ABI, calls, chainId)
  const poolsIdsChunk: any[] = chunk(poolsIdsCallsResult, chunkSize)

  return config.map((p, index) => {
    const [totalSupply, balanceOf]: any[] = poolsIdsChunk[index]
    return {
      sousId: p.sousId,
      data: {
        totalSupply: new BigNumber(totalSupply as any).toJSON(),
        earningTokenBalance: new BigNumber(balanceOf as any),
      },
    }
  })
}
