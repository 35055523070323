import { SerializedStakeConfig } from './types'
// TODO: 这里需要配置周期
// 正式Address
export const epoch = 86400
// 测试
// export const epoch = 60;

export const stakesConfig: SerializedStakeConfig[] = [
  // {
  //   sousId: 0,
  //   stakingToken: bscTokens.cake,
  //   earningToken: bscTokens.cake,
  //   contractAddress: {
  //     97: '0xA69339528d86587244079400Be624aDfa51FB030',
  //     56: '0x8c993C6F644a6C839d32273aE6E0Fb714Ac94ED1',
  //   },
  //   isFinished: false,
  //   exchange: 'pancake',
  //   duration: 2592000,
  //   // duration: 600,
  //   isLp: false,
  // },
]

// 这个池子提供给首页计算总锁仓量
export const poolsConfig = [
  {
    sousId: 0,
    poolId: 0,
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    contractAddress: {
      '56': '0x8c993C6F644a6C839d32273aE6E0Fb714Ac94ED1',
      '97': '',
    },
    isFinished: false,
    exchange: 'pancake',
    duration: 2592000,
    isLp: false,
  },
  {
    sousId: 1,
    poolId: 1,
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    contractAddress: {
      '56': '0x8c993C6F644a6C839d32273aE6E0Fb714Ac94ED1',
      '97': '',
    },
    isFinished: false,
    exchange: 'pancake',
    duration: 5184000,
    isLp: false,
  },
  {
    sousId: 2,
    poolId: 2,
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    contractAddress: {
      '56': '0x8c993C6F644a6C839d32273aE6E0Fb714Ac94ED1',
      '97': '',
    },
    isFinished: false,
    exchange: 'pancake',
    duration: 7776000,
    isLp: false,
  },
  {
    sousId: 3,
    poolId: 3,
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE-USDT',
      name: 'LITE-USDT LP',
      isNative: false,
      isToken: true,
      address: '0x78A3E54dEA17C04B396AB81F88Db185719a9A9Be',
      projectLink: 'https://pancakeswap.finance/',
    },
    stakingLpAToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    stakingLpBToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'USDT',
      name: 'Tether USD',
      isNative: false,
      isToken: true,
      address: '0x55d398326f99059fF775485246999027B3197955',
      projectLink: 'https://tether.to/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    contractAddress: {
      '56': '0x8c993C6F644a6C839d32273aE6E0Fb714Ac94ED1',
      '97': '',
    },
    isFinished: false,
    exchange: 'pancake',
    duration: 2592000,
    isLp: true,
  },
  {
    sousId: 4,
    poolId: 4,
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE-BCC',
      name: 'LITE-BCC LP',
      isNative: false,
      isToken: true,
      address: '0x86209af835Ebb6cf457277DACf0e5195Af220313',
      projectLink: 'https://pancakeswap.finance/',
    },
    stakingLpAToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    stakingLpBToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BCC',
      name: 'BCC',
      isNative: false,
      isToken: true,
      address: '0x9F1839d6DFBEbfc1bBBc94869F5C57784B5C369E',
      projectLink: 'https://liteswap.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'LITE',
      name: 'LITE',
      isNative: false,
      isToken: true,
      address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
      projectLink: 'https://liteswap.finance/',
    },
    contractAddress: {
      '56': '0x8c993C6F644a6C839d32273aE6E0Fb714Ac94ED1',
      '97': '',
    },
    isFinished: false,
    exchange: 'pancake',
    duration: 2592000,
    isLp: true,
  },
  {
    sousId: 5,
    poolId: 0,
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BCC',
      name: 'BCC',
      isNative: false,
      isToken: true,
      address: '0x9F1839d6DFBEbfc1bBBc94869F5C57784B5C369E',
      projectLink: 'https://liteswap.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BCC',
      name: 'BCC',
      isNative: false,
      isToken: true,
      address: '0x9F1839d6DFBEbfc1bBBc94869F5C57784B5C369E',
      projectLink: 'https://liteswap.finance/',
    },
    contractAddress: {
      '56': '0x7C13F9335fb66d905646A9cC00bC876b6D8255e4',
      '97': '',
    },
    isFinished: false,
    exchange: 'pancake',
    duration: 2592000,
    isLp: false,
  },
  {
    sousId: 6,
    poolId: 1,
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BCC',
      name: 'BCC',
      isNative: false,
      isToken: true,
      address: '0x9F1839d6DFBEbfc1bBBc94869F5C57784B5C369E',
      projectLink: 'https://liteswap.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BCC',
      name: 'BCC',
      isNative: false,
      isToken: true,
      address: '0x9F1839d6DFBEbfc1bBBc94869F5C57784B5C369E',
      projectLink: 'https://liteswap.finance/',
    },
    contractAddress: {
      '56': '0x7C13F9335fb66d905646A9cC00bC876b6D8255e4',
      '97': '',
    },
    isFinished: false,
    exchange: 'pancake',
    duration: 7776000,
    isLp: false,
  },
  {
    sousId: 7,
    poolId: 2,
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BCC-USDT',
      name: 'BCC-USDT LP',
      isNative: false,
      isToken: true,
      address: '0x5B0191E3F83CB07268702edEc4584FE1616A7380',
      projectLink: 'https://pancakeswap.finance/',
    },
    stakingLpAToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BCC',
      name: 'BCC',
      isNative: false,
      isToken: true,
      address: '0x9F1839d6DFBEbfc1bBBc94869F5C57784B5C369E',
      projectLink: 'https://liteswap.finance/',
    },
    stakingLpBToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'USDT',
      name: 'Tether USD',
      isNative: false,
      isToken: true,
      address: '0x55d398326f99059fF775485246999027B3197955',
      projectLink: 'https://tether.to/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BCC',
      name: 'BCC',
      isNative: false,
      isToken: true,
      address: '0x9F1839d6DFBEbfc1bBBc94869F5C57784B5C369E',
      projectLink: 'https://liteswap.finance/',
    },
    contractAddress: {
      '56': '0x7C13F9335fb66d905646A9cC00bC876b6D8255e4',
      '97': '',
    },
    isFinished: false,
    exchange: 'pancake',
    duration: 2592000,
    isLp: true,
  },
]
